import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const HealthCare = ({ data }) => {

  return (
    <>
      <Seo
        title="Health Care and Social Assistance in North Central Pennsylvania"
        description="The Health Care and Social Assistance cluster includes industries that provide for the health and well-being of PA SIX residents. Direct patient care forms the cornerstone of this cluster."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption="UPMC Cole, Potter County"
        position="0%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Health Care and Social Assistance</h2>

            <p>
              The Health Care and Social Assistance cluster includes industries
              that provide for the health and well-being of PA SIX residents.
              Direct patient care forms the cornerstone of this cluster.
            </p>
            <p className="title-small">
              Also included are industries that support direct patient care,
              such as ambulance services, health insurance carriers and public
              agencies that oversee health programs.
            </p>
          </div>

          <div className="intro-text">
            <p>
              In addition, social assistance such as continuing care retirement
              communities and assisted living facilities for the elderly are
              included with in this cluster.
            </p>
            <p>
              Registered Nurses and Personal Care Aides are top occupations in
              the region.
            </p>
            <p>
              The health care sector employs some of the highest paying
              occupations and Personal Care Aides, Home Health Aides, and Social
              and Human Service Assistants are among the fastest growing.
            </p>

            <p>
              Registered Nurse, Licensed Practical Nurse, Licensed Vocational
              Nurse, Nurse Practitioner, Certified Nursing Assistant, and
              Certified Registered Nurse Practitioner are among the top
              certifications requested by employers.
            </p>
          </div>
        </section>

        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="UPMC Cole, Potter County"
        />
      </main>

      <PathwaysIntro
        industry="Health Care and Social Assistance"
        image={data.pathwaysImage.sharp.image}
        caption="Christ the King Manor, Clearfield County"
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: healthcare {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(relativePath: { eq: "IMG_3004.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(relativePath: { eq: "Housekeeping-Heroes-2020.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(relativePath: { eq: "UPMC-Cole.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "UPMC-Cole.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;
export default HealthCare;
